$(function() {
    initBuffer();
    initNav();
    initMatchHeight();
});

$(window).resize(function() {
    setTimeout(function() {
        initBuffer();
        initMatchHeight();
    }, 200);
});

function initBuffer() {
    $('.buffer').css({
        height: $('header').outerHeight()
    });
}

function initNav() {
    var btn_navigation = $('header .nav-toggle');
    var nav = $('header nav');

    btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            nav.slideToggle();
        }
    });

    if (nav.find('ul').length > 0) {
        var li = nav.find('li');

        li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle')
                    $(ul).slideUp();
                }
            });

            if ($(this).next('ul').is(':hidden')) {
                e.preventDefault();
                $(this).next('ul').slideDown();
                $(this).children('span').addClass('toggle')
            }
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('.nav_wrapper').addClass('fixed');
        }
        else {
            $('.nav_wrapper').removeClass('fixed');
        }
    });
}

function initMatchHeight() {
    if ($('.matchHeight').length) {
        $('.matchHeight').each(function() {
            $(this).matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            })
        });
    }
}