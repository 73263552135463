var infoBubble = false;

$(function () {
    if ($('#locations').length > 0) {
        initLocations();
    }
});

function initLocations() {
    $.ajax({
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function () {
            $('#btn-search #loader').fadeIn('fast');
            $('#map-holder .overlay').fadeIn('fast');
            $('#map-holder .overlay .loading').fadeIn('fast');
        },
        url: '/request/locations',
        data: {
            query: $('#query').val()
        }
    }).done(function (response) {
        if (response.error === true) {
            var random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
            $('#map').append('<div class="flashmessage-' + random + '">' + response.message + '</div>');

            $('.flashmessage-' + random).fadeIn('fast');

            setTimeout(function () {
                $('.flashmessage-' + random).fadeOut('fast', function () {
                    $(this).remove();
                });
            }, 5000);
        }

        if ($('#query').val() !== '') {
            initPuntenLijst(response);
            initMaps(response, true);
        } else {
            initPuntenLijst(response);
            initMaps(response, true);
        }

        setTimeout(function () {
            $('#btn-search #loader').fadeOut('fast');
            $('#map-holder .overlay .error').fadeOut('fast');
            $('#map-holder .overlay .loading').fadeOut('fast');

            if (response.length === 0) {
                $('#map-holder .overlay').fadeIn('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .no-results').fadeIn('fast');
                }, 300);
            } else {
                $('#map-holder .overlay').fadeOut('fast');
            }
        }, 500);
    });

    initPuntenSearch();
    initPuntenSearchAgain();
    initPuntenSearchError();
}

function initPuntenLijst(response) {
    if ($('.locations').length > 0) {

        if (response.length === 0) {
            response = false;
        }

        var dienst = '',
            json = '';

        if (response.bounds) {
            locations = response.bounds;
        } else {
            locations = response.locations;
        }

        $('.locations').addClass('active');
        $('.locations').html('');

        locations.forEach(function (location) {
            dienst = '<div class="location" data-item="' + location.id + '"><p>';

            if (location.distance || location.distance === 0) {
                dienst += '<span class="name">' + location.name + ' <small class="pull-right">' + Math.ceil(location.distance) + ' km</small></span>';
            } else {
                dienst += '<span class="name">' + location.name + '</span>';
            }

            var telephone = '';
            var fax = '';
            var emailaddress = '';
            var website = '';
            if (location.phone != null && location.phone != "") telephone = '<p class="tel">T: <a href="tel:' + location.phone + '" target="_blank" title="Neem direct contact op">' + location.phone + '</a></p>';
            if (location.fax != null && location.fax != "") fax = '<p class="fax">F: ' + location.fax + '</p>';
            if (location.email != null && location.email != "" && location.id != '45' && location.id != '99') emailaddress = '<p class="email">E: <a href="mailto:' + location.email + '" target="_blank" title="Neem contact op">' + location.email + '</a></p>';
            if (location.website != null && location.website != "") website = '<p class="website">W: <a href="http://' + location.website + '" target="_blank" title="Bezoek de website">' + location.website + '</a></p>';

            dienst +=   location.street + ' ' + location.housenumber + location.housenumber_additions + '<br />' +
                        location.zipcode + ' ' + location.city + '<br />' +
                        telephone +
                        fax +
                        emailaddress +
                        website +
                        '</div>',
                    '</div>';

            if (location.distance !== null) {
                $('.locations').append(dienst);
            }
        });
    }
}

function initMaps(json, fitBounds) {
    if (!fitBounds) {
        fitBounds = false;
    }
    
    json_locations = json.locations;

    var locations = [],
        markers = [];

    json_locations.forEach(function (json_location) {
        locations[json_location.id] = {
            id: json_location.id,
            lat: parseFloat(json_location.latitude),
            lng: parseFloat(json_location.longitude),
            name: json_location.name,
            address: json_location.street + ' ' + json_location.housenumber + json_location.housenumber_additions,
            zipcode: json_location.zipcode,
            city: json_location.city,
            phone: json_location.phone,
            fax: json_location.fax,
            email: json_location.email,
            website: json_location.website
        };
    });

    var json_bounds = json.bounds;
    var bounds = new google.maps.LatLngBounds();
    var map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 52.2076825, lng: 4.15851 }
    });

    json_bounds.forEach(function (json_bound) {
        bounds.extend(new google.maps.LatLng(json_bound.latitude, json_bound.longitude));
    });

    locations.forEach(function(location) {
        var icon = new google.maps.MarkerImage(
            '/img/maps/marker.png',
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new google.maps.Size(30, 36)
        );

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(location.lat, location.lng),
            map: map,
            icon: icon
        });

        var i = location.id;

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                if (infoBubble) {
                    infoBubble.close();
                }

                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';
                if (location.phone != null && location.phone != "") telephone = 'T: <a href="tel:' + location.phone + '" target="_blank" title="Neem direct contact op">' + location.phone + '</a><br />';
                if (location.fax != null && location.fax != "") fax = 'F: ' + location.fax + '<br />';
                if (location.email != null && location.email != "" && location.id != '45' && location.id != '99') emailaddress = 'E: <a href="mailto:' + location.email + '" target="_blank" title="Neem contact op">' + location.email + '</a><br />';
                if (location.website != null && location.website != "") website = 'W: <a href="http://' + location.website + '" target="_blank" title="Bezoek de website">' + location.website + '</a><br />';

                infoBubble = new InfoBubble({
                    map: map,
                    content:
                        '<div class="infoBubble inner"><p>' +
                        '<span class="name">' + location.name + '</span><br />' +
                        location.address + '<br />' +
                        location.zipcode + ' ' + location.city + '<br />' +
                        telephone +
                        fax +
                        emailaddress +
                        website +
                        '</p>' +
                        '</div>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 10,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '../img/maps/close.jpg'
                });

                infoBubble.open(map, marker);

                var listener = google.maps.event.addListenerOnce(map, "idle", function () {
                    map.setZoom(14);
                });
            }
        })(marker, i));

        markers[i] = marker;
    });

    $('.locations .location').each(function () {
        $(this).click(function () {
            if (infoBubble) {
                infoBubble.close();
            }
            
            if ($(window).width() > 767) {
                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';
                if (locations[$(this).attr('data-item')].phone != null && locations[$(this).attr('data-item')].phone != "") telephone = 'T: <a href="tel:' + locations[$(this).attr('data-item')].phone + '" target="_blank" title="Neem direct contact op">' + locations[$(this).attr('data-item')].phone + '</a><br />';
                if (locations[$(this).attr('data-item')].fax != null && locations[$(this).attr('data-item')].fax != "") fax = 'F: ' + locations[$(this).attr('data-item')].fax + '<br />';
                if (locations[$(this).attr('data-item')].email != null && locations[$(this).attr('data-item')].email != "" && locations[$(this).attr('data-item')].id != '45' && locations[$(this).attr('data-item')].id != '99') emailaddress = 'E: <a href="mailto:' + locations[$(this).attr('data-item')].email + '" target="_blank" title="Neem contact op">' + locations[$(this).attr('data-item')].email + '</a><br />';
                if (locations[$(this).attr('data-item')].website != null && locations[$(this).attr('data-item')].website != "") website = 'W: <a href="http://' + locations[$(this).attr('data-item')].website + '" target="_blank" title="Bezoek de website">' + locations[$(this).attr('data-item')].website + '</a><br />';

                infoBubble = new InfoBubble({
                    map: map,
                    content:
                        '<div class="infoBubble inner"><p>' +
                        '<span class="name">' + locations[$(this).attr('data-item')].name + '</span><br />' +
                        locations[$(this).attr('data-item')].address + '<br />' +
                        locations[$(this).attr('data-item')].zipcode + ' ' + locations[$(this).attr('data-item')].city + '<br />' +
                        telephone +
                        fax +
                        emailaddress +
                        website +
                        '</p>' +
                        '</div>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 10,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    minHeight: 200,
                    maxHeight: false,
                    closeSrc: '../img/maps/close.jpg'
                });

                var listener = google.maps.event.addListenerOnce(map, "idle", function () {
                    map.setZoom(14);
                });

                infoBubble.open(map, markers[$(this).attr('data-item')]);
            } else {
                window.open(
                    'https://www.google.com/maps/place/' + locations[$(this).attr('data-item')].address.replace(' ', '+') + ',+' + locations[$(this).attr('data-item')].zipcode.replace(' ', '+') + '+' + locations[$(this).attr('data-item')].city + '/',
                    '_blank' // <- This is what makes it open in a new window.
                );
            }
        });
    });

    if (fitBounds) {
        map.fitBounds(bounds);
    }

    var listener = google.maps.event.addListener(map, "idle", function () {
        if (!fitBounds) {
            map.setZoom(7);
        }
    });
    
    google.maps.event.removeListener(listener);

    var markerCluster = new MarkerClusterer(map, markers, {
        styles: [
            {
                url: '/img/maps/cluster_35.png',
                height: 35,
                width: 35,
                textColor: 'white',
                opt_anchor: [16, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: '/img/maps/cluster_45.png',
                height: 45,
                width: 45,
                textColor: 'white',
                opt_anchor: [24, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: '/img/maps/cluster_55.png',
                height: 55,
                width: 55,
                textColor: 'white',
                opt_anchor: [32, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: 'https://googlemaps.github.io/js-marker-clusterer/images/pin.png',
                height: 48,
                width: 30,
                anchor: [-18, 0],
                textColor: '#ffffff',
                textSize: 10,
                iconAnchor: [15, 48]
            }
        ]
    });
}

function initPuntenSearch() {
    $('#frmLocations').submit(function (e) {
        e.preventDefault();

        $('#query').blur();
        $('button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeIn('fast');
            },
            url: '/request/locations',
            data: {
                query: $('#query').val()
            }
        }).done(function (response) {
            $('#map-holder .overlay #retry-form #search-opnieuw').val($('#query').val());

            if (response.error === true) {
                var random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
                $('#map').append('<div class="flashmessage-' + random + '">' + response.message + '</div>');

                $('.flashmessage-' + random).fadeIn('fast');

                setTimeout(function () {
                    $('.flashmessage-' + random).fadeOut('fast', function () {
                        $(this).remove();
                    });
                }, 5000);
            }

            initPuntenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(response).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function initPuntenSearchAgain() {
    $('#map-holder .overlay #retry-form #search-opnieuw').val($('#query').val());

    $('#map-holder .overlay #retry-form').submit(function (e) {
        e.preventDefault();

        $('#map-holder .overlay #retry-form #search-opnieuw').blur();
        $('#map-holder .overlay #retry-form button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .no-results').fadeOut('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .loading').fadeIn('fast');
                }, 300);
            },
            url: '/request/locations',
            data: {
                query: $('#map-holder .overlay #retry-form #search-opnieuw').val(),
                radius: $('#map-holder .overlay #retry-form #radius').val()
            }
        }).done(function (response) {
            $('#query').val($('#map-holder .overlay #retry-form #search-opnieuw').val());

            if (response.error === true) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeIn('fast');
            }

            initPuntenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(response).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function initPuntenSearchError() {
    $('#map-holder .overlay #error-form').submit(function (e) {
        e.preventDefault();

        $('#map-holder .overlay #error-form #search-opnieuw').blur();
        $('#map-holder .overlay #error-form button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .no-results').fadeOut('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .loading').fadeIn('fast');
                }, 300);
            },
            url: '/request/locations',
            data: {
                query: $('#map-holder .overlay #error-form #search-opnieuw').val(),
                radius: $('#map-holder .overlay #error-form #radius').val()
            }
        }).done(function (response) {
            $('#query').val($('#map-holder .overlay #error-form #search-opnieuw').val());
            $('#map-holder .overlay #retry-form #search-opnieuw').val($('#map-holder .overlay #error-form #search-opnieuw').val());

            if (response.error === true) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay').fadeIn('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .error').fadeIn('fast');
                }, 300);
            }

            initPuntenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(response).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}